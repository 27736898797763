// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/detailPage/icon-title.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".item[data-v-6ae5767f] {\n  margin-bottom: 20px;\n  background: #ffffff;\n  border-radius: 10px;\n  padding-bottom: 10px;\n}\n.item .item-label[data-v-6ae5767f] {\n  height: 48px;\n  font-size: 18px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n}\n.item .item-label[data-v-6ae5767f]::before {\n  content: '';\n  display: inline-block;\n  width: 23px;\n  height: 19px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  margin-right: 7px;\n}\n.item .item-content[data-v-6ae5767f] {\n  background-color: #F7FAFF;\n  margin: 15px 25px;\n  padding: 0 10px 10px;\n  border-radius: 10px;\n  overflow: hidden;\n}\n.item .item-content .title[data-v-6ae5767f] {\n  text-align: center;\n  padding: 10px 0;\n  margin: 0 15px 5px;\n  border-bottom: 1px solid #E8F3FE;\n}\n.item .item-content .scroll-wrap[data-v-6ae5767f] {\n  min-height: 100px;\n  max-height: 400px;\n  overflow: auto;\n}\n.item .item-content .scroll-wrap .flex[data-v-6ae5767f] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 40px;\n  padding: 0 20px;\n  border-radius: 20px;\n  color: #333;\n  cursor: pointer;\n}\n.item .item-content .scroll-wrap .flex .label[data-v-6ae5767f] {\n  max-width: 110px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.item .item-content .scroll-wrap .flex[data-v-6ae5767f]:hover,\n.item .item-content .scroll-wrap .flex.active[data-v-6ae5767f] {\n  color: #5DAAF0;\n  background-color: #E6F3FF;\n}\n[data-v-6ae5767f]::-webkit-scrollbar {\n  width: 4px;\n  height: 4px;\n}\n[data-v-6ae5767f]::-webkit-scrollbar-track {\n  border-radius: 4px;\n}\n[data-v-6ae5767f]::-webkit-scrollbar-thumb {\n  border-radius: 2px;\n  background-color: #eee;\n}\n", ""]);
// Exports
module.exports = exports;
